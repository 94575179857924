import http from './http'
import Resource from './resource'

const path = 'admin/headers'

class Header extends Resource {
  constructor(_http) {
    super(path, _http)
  }

  select(opt = {}) {
    return this.http.get(`api/${path}/select/list`, opt)
  }

  order(opt = {}) {
    return this.http.get('api/admin/headers-order', opt)
  }

  applyOrder(data, opt = {}) {
    return this.http.post('api/admin/headers-order', data, opt)
  }

  applyOrderAll(data, opt = {}) {
    return this.http.post('api/admin/headers-order/apply/all', data, opt)
  }
}

const HeaderRequest = new Header(http)

export default HeaderRequest
