import HeaderRequest from '@/@api/header'
import { ref, computed } from '@vue/composition-api'
// import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debouncedWatch } from '@vueuse/core'

const links = ref([])

export default function useHeader() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // const links = ref([])

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { label: 'Icono', key: 'icon' },
    {
      label: 'Título',
      key: 'name',
      sortable: true,
      thStyle: { width: '100%' },
    },
    { label: 'Traducciones', key: 'urls', thStyle: { minWidth: '220px' } },
    { label: 'Clase css', key: 'className', thStyle: { minWidth: '200px' } },
    { label: 'Icono FontAwesome', key: 'faIcon', thStyle: { minWidth: '220px' } },
    { label: 'Activo', key: 'status', sortable: true },
    { label: 'Acciones', key: 'actions' },
  ]
  const perPage = ref(10)
  const totalLinks = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalLinks.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  debouncedWatch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  }, { debounce: 400 })

  const fetchLinks = (ctx, callback) => {
    HeaderRequest.list({
      params: {
        q: searchQuery.value,
        paginate: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      },
    })
      .then(response => {
        const { data, total } = response.data.data

        callback(data.map(item => ({ ...item, loading: false })))
        totalLinks.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching header list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  async function fetchLinksSelector(isMemo = false) {
    if (isMemo && links.value.length) {
      return Promise.resolve(links.value)
    }

    try {
      const { data } = await HeaderRequest.select()

      const list = data.data.map(item => ({
        id: item.id,
        value: item.id,
        label: item.name,
        icon: item.icon,
      }))

      links.value = list

      return list
    } catch (error) {
      throw new Error(error)
    }
  }

  async function linkShow(id) {
    try {
      const { data } = await HeaderRequest.show(id)

      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: data.message,
      //     icon: 'CheckIcon',
      //     variant: 'success',
      //   },
      // })
      return data.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async function linkStore(body) {
    try {
      const { data } = await HeaderRequest.store(body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function linkUpdate(id, body) {
    try {
      const { data } = await HeaderRequest.update(id, body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function linkDelete(id) {
    try {
      const { data } = await HeaderRequest.destroy(id)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function linkDuplicate(id) {
    try {
      const { data } = await HeaderRequest.show(id)
      const link = JSON.parse(JSON.stringify(data.data))
      link.id = null

      await HeaderRequest.store(link)

      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: 'data.message',
      //     icon: 'CheckIcon',
      //     variant: 'success',
      //   },
      // })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function saveLinksOrder(form) {
    try {
      const { data } = await HeaderRequest.applyOrder(form)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function saveApplyOrderAll(form) {
    try {
      const { data } = await HeaderRequest.applyOrderAll(form)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function fetchLinksOrder(country) {
    try {
      const { data } = await HeaderRequest.order({ params: { country } })

      const list = data.data
        .map(link => {
          const order = link.orders && link.orders.length ? link.orders[0] : { visible: true, order: 0 }
          return {
            id: link.id,
            label: link.name,
            icon: link.icon,
            faIcon: link.faIcon,
            visible: order.visible,
            require_auth: order.require_auth,
            order: order.order,
            device: link.device,
          }
        })
        .sort((a, b) => a.order - b.order)

      return list
    } catch (error) {
      throw new Error(error)
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  fetchLinksSelector(true).then(list => {
    links.value = list
  })

  const resolveIsoNav = iso => {
    if (!iso) return ''

    const dic = links.value.reduce((acc, item) => {
      acc[item.value] = item.label

      return acc
    }, {})

    return dic[iso] ?? iso
  }

  return {
    links,
    fetchLinks,
    fetchLinksSelector,
    tableColumns,
    perPage,
    currentPage,
    totalLinks,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,

    resolveIsoNav,

    refetchData,
    linkShow,
    linkStore,
    linkUpdate,
    linkDelete,
    linkDuplicate,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    saveLinksOrder,
    fetchLinksOrder,
    saveApplyOrderAll,
  }
}
