<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <!-- @reset.prevent="resetForm" -->
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">

                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                  <!-- Header: Left Content -->
                  <div class="flex-grow-1 pr-2">
                    <validation-provider
                      #default="validationContext"
                      name="Título"
                      rules="required"
                    >
                      <b-form-group
                        label="Título"
                        label-for="name"
                      >
                        <b-form-input
                          id="name"
                          v-model="data.name"
                          :state="getValidationState(validationContext)"
                          trim
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </div>

                  <!-- Header: Right Content -->
                  <div
                    class="invoice-number-date mt-md-0 mt-2"
                    style="min-width: 320px;"
                  >
                    <!-- ICON -->
                    <!-- <validation-provider
                      #default="validationContext"
                      name="Icono"
                      rules="required"
                    >
                      <b-form-group
                        label="Icono"
                        label-for="icon"
                        :state="getValidationState(validationContext)"
                      >
                        <v-select
                          v-model="data.icon"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="iconsList"
                          :clearable="false"
                          input-id="icon"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider> -->
                    <div
                      class="d-flex align-items-center"
                      :style="isDragging ? 'pointer-events: none' : ''"
                    >
                      <b-form-group
                        label="Icono"
                        label-for="file"
                      >
                        <b-form-file
                          id="file"
                          ref="refInputGlobalEl"
                          accept=".jpg, .png, .webp, .gif, .svg"
                          placeholder="Arraste y suelte o haga clic para subir el icono"
                          :disabled="loading"
                          @change="changeInputFile"
                        />
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <!-- <hr class="invoice-spacing"> -->

              <!-- Note -->
              <!-- <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">Nota: </span>
                <b-form-textarea
                  v-model="bannerData.note"
                  :disabled="loading"
                />
              </b-card-body> -->

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->
              <b-card-body
                class="invoice-padding pt-0"
              >
                <div class="invoice-spacing">
                  <h6 class="mb-2">
                    Idiomas:
                  </h6>

                  <div
                    v-for="(url, index) in data.urls"
                    :key="index"
                    class="d-flex align-items-start w-100"
                  >
                    <b-form-group
                      label="Lenguaje"
                      :label-for="`lang-${index}`"
                      class="flex-grow-0 mr-2"
                      style="min-width: 180px"
                    >
                      <b-form-input
                        :id="`lang-${index}`"
                        v-model="url.lang.label"
                        placeholder="Lenguaje"
                        readonly
                      />
                    </b-form-group>
                    <!-- <validation-provider
                      #default="validationContext"
                      name="Lenguaje"
                      rules="required"
                    >
                      <b-form-group
                        label="Lenguaje"
                        :label-for="`lang-${index}`"
                        :state="getValidationState(validationContext)"
                        class="flex-grow-0 mr-2"
                        style="min-width: 200px"
                      >
                        <v-select
                          :id="`lang-${index}`"
                          v-model="url.lang"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="languages"
                          input-id="invoice-data-client"
                          :clearable="false"
                          :disabled="loading"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider> -->
                    <validation-provider
                      #default="validationContext"
                      name="Título"
                      rules="required"
                    >
                      <b-form-group
                        label="Título"
                        :label-for="`name-${index}`"
                        class="flex-grow-0 mr-2"
                        style="min-width: 200px"
                      >
                        <b-form-input
                          :id="`name-${index}`"
                          v-model="url.name"
                          placeholder="Título"
                          :state="getValidationState(validationContext)"
                          :disabled="loading"
                          trim
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider
                      #default="validationContext"
                      name="Url de redirección"
                      rules="required"
                      style="width: 100%;"
                    >
                      <b-form-group
                        label="Url de redirección"
                        :label-for="`url-${index}`"
                        class="flex-grow-1"
                      >
                        <b-form-input
                          :id="`url-${index}`"
                          v-model="url.url"
                          placeholder="Url"
                          :state="getValidationState(validationContext)"
                          :disabled="loading"
                          trim
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <!-- <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      :disabled="loading"
                      variant="outline-danger"
                      class="mt-2 ml-2"
                      @click="removeLang(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button> -->
                  </div>

                  <!-- <div>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :disabled="loading"
                      size="sm"
                      variant="primary"
                      @click="addNewItemInUrlForm"
                    >
                      Añadir URL
                    </b-button>
                  </div> -->
                </div>
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
        <!-- Action Buttons -->
        <b-card>
          <p class="small text-body-heading mb-0">
            Preview
          </p>
          <div
            class="icon-container d-flex justify-content-center mb-2 cursor-pointer"
            @click="handleInputFile"
          >
            <app-image
              :src="data.icon"
              alt="icon"
              width="120px"
              height="120px"
            />
          </div>

          <b-form-group
            label="Clase css"
            label-for="className"
          >
            <b-form-input
              id="className"
              v-model="data.className"
              placeholder="class-name other-class"
              :disabled="loading"
              trim
            />
          </b-form-group>

          <b-form-group
            label="Icono de FontAwesome"
            label-for="faIcon"
          >
            <b-form-input
              id="faIcon"
              v-model="data.faIcon"
              placeholder="fas fa-basketball-ball"
              :disabled="loading"
              trim
            />
          </b-form-group>

          <validation-provider
            #default="validationContext"
            name="Dispositivo donde mostrar"
            rules="required"
          >
            <b-form-group
              label="Dispositivo donde mostrar"
              label-for="device"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="data.device"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="deviceList"
                :clearable="false"
                input-id="device"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="position-relative">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              type="button"
              :disabled="loading"
              size="sm"
              style="top: 0; right: 0;"
              class="position-absolute mt-n25 px-1 py-25"
              @click="selectAllDomains"
            >Todos</b-button>

            <validation-provider
              #default="validationContext"
              name="Dominios donde mostrar"
              rules="required"
            >
              <b-form-group
                label="Dominios donde mostrar"
                label-for="domains"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="data.domains"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="domains"
                  :clearable="false"
                  input-id="domains"
                  multiple
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>

          <hr class="invoice-spacing">

          <div
            class="d-flex justify-content-between align-items-center my-1"
          >
            <label
              for="allRequiredAuth"
              class="w-100 d-flex justify-content-between mr-1 font-small-3"
            >Activo <span>{{ data.status ? 'SI' : 'NO' }}</span></label>
            <b-form-checkbox
              id="allRequiredAuth"
              v-model="data.status"
              :disabled="loading"
              switch
            />
          </div>

          <hr class="invoice-spacing">

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :disabled="loading"
            block
            @click="onSubmit"
          >
            <template v-if="loading">
              <b-spinner
                class="mr-1"
                label="Small Spinning"
                small
              />
              Guardando...
            </template>
            <template v-else>
              Guardar
            </template>
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :disabled="loading"
            block
            type="button"
            @click="saveAndNew"
          >
            <template v-if="loading">
              <b-spinner
                class="mr-1"
                label="Small Spinning"
                small
              />
              Guardando...
            </template>
            <template v-else>
              Guardar y crear otro
            </template>
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <backdrop-files
      v-if="isDragging"
      text="Suelta el excel aquí"
    />
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  // BFormTextarea,
  BFormCheckbox,
  // VBToggle,
  BFormInput,
  // BMedia,
  // BCardText,
  // BMediaAside,
  // BMediaBody,
  // BImg,
  BFormFile,
  // BLink,
  BSpinner,
  // BSidebar,
  // BForm,
  // BFormGroup,
  // BFormInput,
  BFormInvalidFeedback,
  // BButton,
  // BFormCheckbox,
  // BSpinner,
  // BImg,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onMounted, ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@/@core/utils/utils'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import AppImage from '@core/components/app-image/AppImage.vue'
import { useFileToBase64 } from '@/@core/comp-functions/forms/form-utils'
import BackdropFiles from '@/views/banner/BackdropFiles.vue'
import iconsList from '@/@fake-db/data/other/icons'
import useLangs from '@/views/langs/useLangs'
import useDomains from '@/views/domains/useDomains'
import { devices } from '@core/constants'
import useHeader from '../useHeader'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    // BFormTextarea,
    BFormCheckbox,
    // VBToggle,
    BFormInput,
    // BMedia,
    // BCardText,
    // BMediaAside,
    // BMediaBody,
    // BImg,
    BFormFile,
    // BLink,
    BSpinner,
    // BSidebar,
    // BForm,
    // BFormGroup,
    // BFormInput,
    BFormInvalidFeedback,
    // BButton,
    // BFormCheckbox,
    // BSpinner,
    vSelect,
    // BImg,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BackdropFiles,
    AppImage,
  },
  directives: {
    Ripple,
  },
  model: {
    // prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    // isAddNewUserSidebarActive: {
    //   type: Boolean,
    //   required: true,
    // },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      iconsList,
    }
  },
  created() {
    // window.addEventListener('resize', this.initTrHeight)
    // console.log('mounted')
    window.addEventListener('dragover', this.dragover)
    window.addEventListener('dragleave', this.dragleave)
    window.addEventListener('drop', this.drop)
  },
  destroyed() {
    // window.removeEventListener('resize', this.initTrHeight)
    // console.log('destroyed')
    window.removeEventListener('dragover', this.dragover)
    window.removeEventListener('dragleave', this.dragleave)
    window.removeEventListener('drop', this.drop)
  },
  methods: {
    handleInputFile() {
      this.$refs.refInputGlobalEl.$refs.input.click()
    },
    // Methods Drag and drop file
    dragover(e) {
      e.preventDefault()
      this.isDragging = true
    },
    dragleave() {
      this.isDragging = false
    },
    drop(e) {
      e.preventDefault()
      this.changeInputFile(e.dataTransfer.files)
      this.isDragging = false
    },
    async changeInputFile(e) {
      let inputFiles = []

      if (e instanceof Event) {
        inputFiles = e.target.files
      } else if (e instanceof FileList) {
        inputFiles = e
      } else if (Array.isArray(e)) {
        inputFiles = e
      } else {
        inputFiles = this.$refs.refInputGlobalEl?.files ?? []
      }

      const file = inputFiles[0]

      const base64 = await useFileToBase64(file)

      this.data.icon = base64
    },
  },
  setup() {
    const { route } = useRouter()

    const deviceList = ref(devices)

    const itemFormBlankUrl = {
      lang: '',
      name: '',
      url: '',
    }
    const blankData = {
      id: null,
      icon: '',
      name: '',
      urls: [],
      className: '',
      faIcon: '',
      device: { ...deviceList.value[0] },
      domains: [],
      status: true,
    }

    const { fetchLangsSelector } = useLangs()
    const { fetchDomainsSelector } = useDomains()

    const isDragging = ref(false)
    const loading = ref(false)
    const data = ref({ ...blankData })
    const languages = ref([])
    const domains = ref([])
    const refInputGlobalEl = ref(null)

    const { linkShow, linkStore, linkUpdate } = useHeader()

    const getBlankData = () => {
      const urls = languages.value.map(lang => ({
        ...itemFormBlankUrl,
        lang,
      }))

      return {
        ...blankData,
        urls,
        device: { ...deviceList.value[0] },
        domains: JSON.parse(JSON.stringify(domains.value)),
      }
    }

    const resetData = () => {
      data.value = getBlankData()
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    const selectAllDomains = () => {
      data.value = {
        ...data.value,
        domains: JSON.parse(JSON.stringify(domains.value)),
      }
    }

    const onSubmit = async () => {
      if (loading.value) return

      loading.value = true

      const form = { ...data.value }
      form.urls = form.urls.map(url => ({ ...url, lang_id: url.lang.id }))
      form.device = form.device.value
      form.domains = form.domains.map(d => d.id)

      if (form.icon && form.icon.startsWith('http')) {
        delete form.icon
      }

      try {
        if (form.id) {
          await linkUpdate(form.id, form)
        } else {
          await linkStore(form)
        }

        router.back()
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const saveAndNew = async () => {
      if (loading.value) return

      loading.value = true

      const form = { ...data.value }
      form.urls = form.urls.map(url => ({ ...url, lang_id: url.lang.id }))
      form.domains = form.domains.map(d => d.id)

      if (form.icon && form.icon.startsWith('http')) {
        delete form.icon
      }

      try {
        if (form.id) {
          await linkUpdate(form.id, form)
        } else {
          await linkStore(form)
        }

        data.value = getBlankData()
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    onMounted(async () => {
      const { id } = route.value.params

      const langList = await fetchLangsSelector()
      const domainsList = await fetchDomainsSelector()
      languages.value = langList
      domains.value = domainsList.map(d => ({ ...d, label: `${d.label} (${d.domain})` }))

      try {
        if (!id) {
          data.value = getBlankData()
        } else {
          const item = await linkShow(id)

          const itemDomains = item.domains ? item.domains.map(d => d.id) : null

          data.value = {
            id: item.id,
            icon: item.icon,
            name: item.name,
            urls: langList.map(i => {
              const itemLang = item.urls.find(j => j.lang_id === i.id)
              return {
                lang: i,
                name: itemLang?.name ?? '',
                url: itemLang?.url ?? '',
              }
            }),
            className: item.className,
            faIcon: item.faIcon,
            device: deviceList.value.find(d => d.value === item.device) ?? deviceList.value[0],
            domains: itemDomains ? domains.value.filter(d => itemDomains.includes(d.id)) : [...domains.value],
            status: item.status,
          }
        }
      } catch (error) {
        console.error(error)
      }
    })

    return {
      isDragging,
      data,
      languages,
      domains,
      loading,
      onSubmit,
      saveAndNew,
      refInputGlobalEl,

      refFormObserver,
      getValidationState,
      resetForm,
      deviceList,
      selectAllDomains,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.icon-container > div,
.icon-container > img {
  width: 120px !important;
  height: 120px !important;
  border-radius: 0.357rem !important;
  overflow: hidden !important;
}

.icon-container svg {
  width: 100% !important;
  height: 100% !important;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
